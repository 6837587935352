import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { PillComponent } from '@dougs/ds';
import { Category } from '@dougs/operations/dto';
import { UserStateService } from '@dougs/user/shared';
import { HighlightSearchPipe } from '../../../pipes/highlight-search.pipe';

@Component({
  selector: 'dougs-category-content',
  templateUrl: './category-content.component.html',
  styleUrls: ['./category-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, PillComponent, HighlightSearchPipe],
  providers: [HighlightSearchPipe],
})
export class CategoryContentComponent {
  @Input() breakdownIsInbound!: boolean;
  @Input() category!: Category;
  @Input() isPreferred = false;

  constructor(public readonly userStateService: UserStateService) {}

  showInboundPill(category: Category): boolean {
    return this.breakdownIsInbound === undefined ? category.isInbound : this.breakdownIsInbound !== category.isInbound;
  }

  trackByAccount(index: number, item: number): number {
    return item;
  }
}

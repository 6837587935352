import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  computed,
  Inject,
  OnInit,
  Signal,
  signal,
  WritableSignal,
} from '@angular/core';
import {
  AbstractControl,
  FormGroup,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { lastValueFrom } from 'rxjs';
import { take } from 'rxjs/operators';
import { CompanyStateService } from '@dougs/company/shared';
import { SourceDocumentAttachment, SourceDocumentType } from '@dougs/core/files';
import { FormService } from '@dougs/core/form';
import {
  ButtonComponent,
  CheckboxComponent,
  ConfirmationModalComponent,
  ControlFormFieldDirective,
  ErrorFormFieldDirective,
  FileInputComponent,
  FilePillComponent,
  FormFieldComponent,
  InputDatepickerComponent,
  LabelFormFieldDirective,
  LoaderFullpageComponent,
  MODAL_DATA,
  ModalCloseDirective,
  ModalContentDirective,
  ModalFooterDirective,
  ModalRef,
  ModalService,
  ModalTitleDirective,
  OverlayCloseEvent,
  PanelInfoComponent,
  SelectComponent,
  SelectOptionComponent,
  SourceDocumentPillComponent,
  SuffixFormFieldDirective,
  TrackByPipe,
  VendorInvoicePillComponent,
} from '@dougs/ds';
import { Operation, SLOT_NAME } from '@dougs/operations/dto';
import { NotValidatedOperationsStateService, SourceDocumentAttachmentUtils } from '@dougs/operations/shared';
import { Supplier } from '@dougs/suppliers/dto';
import { SupplierStateService } from '@dougs/suppliers/shared';
import { VendorInvoice } from '@dougs/vendor-invoice/dto';
import { VendorInvoiceStateService } from '@dougs/vendor-invoice/shared';
import { createdOperationDateValidatorForCompany } from '../../validators/operation.validator';
import { CategoryComponent } from '../category-modal/category.component';

@Component({
  selector: 'dougs-supplier-invoice-operation-modal',
  standalone: true,
  imports: [
    CommonModule,
    ButtonComponent,
    CategoryComponent,
    ControlFormFieldDirective,
    ErrorFormFieldDirective,
    FileInputComponent,
    FilePillComponent,
    FormFieldComponent,
    InputDatepickerComponent,
    LabelFormFieldDirective,
    LoaderFullpageComponent,
    ModalCloseDirective,
    ModalContentDirective,
    ModalFooterDirective,
    ModalTitleDirective,
    PanelInfoComponent,
    ReactiveFormsModule,
    SelectComponent,
    SelectOptionComponent,
    SuffixFormFieldDirective,
    TrackByPipe,
    VendorInvoicePillComponent,
    CheckboxComponent,
    SourceDocumentPillComponent,
  ],
  templateUrl: './supplier-invoice-operation-modal.component.html',
  styleUrls: ['./supplier-invoice-operation-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SupplierInvoiceOperationModalComponent implements OnInit {
  suppliers: Supplier[] = [];
  operation?: Operation;
  fromCandidateInvoice = false;

  filesMap: Map<string, File> = new Map<string, File>();

  private readonly vendorInvoices: WritableSignal<VendorInvoice[]> = signal<VendorInvoice[]>([]);
  public vendorInvoices$: Signal<VendorInvoice[]> = computed(() => this.vendorInvoices());
  vendorInvoiceSourceDocumentAttachment$: Signal<SourceDocumentAttachment[]> = computed(() =>
    this.vendorInvoices$().map((vendorInvoice) =>
      SourceDocumentAttachmentUtils.createSourceDocumentAttachmentFromVendorInvoice(vendorInvoice),
    ),
  );

  private readonly tmpSourceDocumentAttachments: WritableSignal<SourceDocumentAttachment[]> = signal<
    SourceDocumentAttachment[]
  >([]);
  tmpSourceDocumentAttachments$: Signal<SourceDocumentAttachment[]> = this.tmpSourceDocumentAttachments.asReadonly();

  allSourceDocumentAttachments$: Signal<SourceDocumentAttachment[]> = computed(() => [
    ...this.vendorInvoiceSourceDocumentAttachment$(),
    ...this.tmpSourceDocumentAttachments$(),
  ]);

  private readonly isLoading: WritableSignal<boolean> = signal(false);
  isLoading$: Signal<boolean> = this.isLoading.asReadonly();

  formGroup!: FormGroup;

  get date(): AbstractControl | null {
    return this.formGroup.get('date');
  }

  get amount(): AbstractControl | null {
    return this.formGroup.get('amount');
  }

  get isCreditInvoice(): AbstractControl | null {
    return this.formGroup.get('isCreditInvoice');
  }

  get category(): AbstractControl | null {
    return this.formGroup.get('category');
  }

  get supplier(): AbstractControl | null {
    return this.formGroup.get('supplier');
  }

  get memo(): AbstractControl | null {
    return this.formGroup.get('memo');
  }

  get invoiceNumber(): AbstractControl | null {
    return this.formGroup.get('invoiceNumber');
  }

  constructor(
    @Inject(MODAL_DATA)
    public readonly data: {
      operation: Operation;
      fromCandidateInvoice?: boolean;
      operationAmount?: number;
      operationDate?: Date;
    },
    private readonly companyStateService: CompanyStateService,
    private readonly supplierStateService: SupplierStateService,
    private readonly cdr: ChangeDetectorRef,
    private readonly modalRef: ModalRef,
    private readonly notValidatedOperationsStateService: NotValidatedOperationsStateService,
    private readonly modalService: ModalService,
    private readonly fb: UntypedFormBuilder,
    public formService: FormService,
    private readonly vendorInvoiceStateService: VendorInvoiceStateService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.operation = this.data?.operation;
    this.fromCandidateInvoice = !!this.data?.fromCandidateInvoice;
    await this.supplierStateService.refreshSuppliers(this.companyStateService.activeCompany.id);
    this.suppliers = await lastValueFrom(this.supplierStateService.suppliers$.pipe(take(1)));
    this.buildForm();

    if (this.suppliers.length === 1) {
      this.supplier?.setValue(this.suppliers[0].id);
    }

    this.cdr.markForCheck();
  }

  buildForm(): void {
    this.formGroup = this.fb.group({
      date: [
        {
          value: this.data?.operationDate ?? this.operation?.date ?? new Date(),
          disabled: this.data?.fromCandidateInvoice ?? false,
        },
        [
          Validators.required,
          createdOperationDateValidatorForCompany(this.companyStateService.activeCompany, SLOT_NAME.INVOICE_SUPPLIER),
        ],
      ],
      amount: [this.operation?.amount || this.data?.operationAmount || '', [Validators.required]],
    });

    if (!this.operation) {
      this.formGroup.addControl('isCreditInvoice', new UntypedFormControl(false, [Validators.required]));
      this.formGroup.addControl('category', new UntypedFormControl('', [Validators.required]));
      this.formGroup.addControl('supplier', new UntypedFormControl('', [Validators.required]));
      this.formGroup.addControl('memo', new UntypedFormControl(null));
      this.formGroup.addControl(
        'invoiceNumber',
        new UntypedFormControl('', this.fromCandidateInvoice ? null : [Validators.required]),
      );
    }
  }

  async onSubmit(): Promise<void> {
    this.formGroup.markAllAsTouched();

    if (!this.formGroup.invalid) {
      this.isLoading.set(true);
      let result: Operation | null;

      if (this.operation) {
        result = await this.updateExpenseOperation();
      } else {
        result = await this.saveExpenseOperation();
      }

      if (result) {
        this.modalRef.close(result);
      }
      this.isLoading.set(false);
    }
  }

  private async saveExpenseOperation(): Promise<Operation | null> {
    const { date, isCreditInvoice, category, memo, amount, supplier, invoiceNumber } = this.formGroup.getRawValue();
    const supplierName: string | undefined = this.suppliers.find((s) => s.id === supplier)?.name;
    // eslint-disable-next-line @typescript-eslint/typedef
    let invoiceSupplierLabel = '';
    const metadata: Record<string, string | string[] | number | boolean> = {
      invoiceNumber,
      status: ['pending'],
    };

    if (invoiceNumber) {
      invoiceSupplierLabel = `N°${invoiceNumber} - `;
    }

    if (supplierName) {
      invoiceSupplierLabel += `${supplierName}`;
      metadata.supplierName = supplierName;
    }
    if (this.fromCandidateInvoice) {
      invoiceSupplierLabel = 'Ceci est une facture provisoire. Pensez à importer votre facture dès que possible.';
      metadata.isTmpInvoice = true;
    }
    if (memo) {
      invoiceSupplierLabel += ` - ${memo}`;
    }

    const operationCreated: Operation | null = await this.notValidatedOperationsStateService.createOperation(
      this.companyStateService.activeCompany.id,
      {
        type: SLOT_NAME.INVOICE_SUPPLIER,
        date,
        memo: invoiceSupplierLabel,
        amount,
        isInbound: isCreditInvoice,
        metadata,
        breakdowns: [
          {
            amount,
            categoryId: category.id,
          },
          {
            amount: 0,
            categoryId: -1,
            isCounterpart: true,
            associationData: {
              supplierId: supplier,
            },
          },
        ],
      },
      Array.from(this.filesMap.values()),
    );

    if (operationCreated && this.vendorInvoices$()?.length) {
      await Promise.all(
        this.vendorInvoices$().map((vendorInvoice) =>
          this.vendorInvoiceStateService.attachOperation(operationCreated, vendorInvoice),
        ),
      );
      await this.notValidatedOperationsStateService.refreshOperationById(
        operationCreated.companyId,
        operationCreated.id,
      );
    }

    return operationCreated;
  }

  private async updateExpenseOperation(): Promise<Operation | null> {
    const { isCreditInvoice, date, amount } = this.formGroup.value;
    const updatedOperation: Operation = {
      ...this.operation,
      date,
      amount,
      isInbound: !isCreditInvoice,
    } as Operation;
    return await this.notValidatedOperationsStateService.updateOperation(updatedOperation);
  }

  removeVendorInvoice(): void {
    this.modalService
      .open(ConfirmationModalComponent, {
        data: {
          title: "Supprimer la facture d'achat",
          body: `Êtes-vous sûr de vouloir supprimer cette facture d'achat ? Cette action est définitive.`,
          noText: 'Annuler',
          yesText: "Oui, supprimer la facture d'achat",
        },
      })
      .afterClosed$.pipe(take(1))
      .subscribe(async (res: OverlayCloseEvent<unknown>) => {
        if (res.data && this.operation) {
          const success = await this.notValidatedOperationsStateService.removeOperation(this.operation);
          if (success) {
            this.modalRef.close();
          }
        }
      });
  }

  onUploadFiles(files: FileList): void {
    Array.from(files).forEach((file: File) => {
      const tmpSda: SourceDocumentAttachment =
        SourceDocumentAttachmentUtils.createSourceDocumentAttachmentFromFile(file);
      this.filesMap.set(tmpSda.sourceDocument.tempUuid as string, file);
      this.tmpSourceDocumentAttachments.update((tmpSdas) => [...tmpSdas, tmpSda]);
    });
  }

  deleteTmpSourceDocumentAttachment(sourceDocumentAttachment: SourceDocumentAttachment): void {
    this.tmpSourceDocumentAttachments.update((tmpSda) =>
      tmpSda.filter((tmp) => tmp.sourceDocument?.tempUuid !== sourceDocumentAttachment.sourceDocument?.tempUuid),
    );
    this.filesMap.delete(sourceDocumentAttachment.sourceDocument.tempUuid as string);
  }

  onDetachTempVendorInvoice(vendorInvoiceId: string): void {
    this.vendorInvoices.update((currentVendorInvoices: VendorInvoice[]) =>
      currentVendorInvoices.filter((tempVendorInvoice: VendorInvoice) => tempVendorInvoice.id !== vendorInvoiceId),
    );
  }

  onDeleteSourceDocumentAttachment(sourceDocumentAttachment: SourceDocumentAttachment): void {
    if (
      sourceDocumentAttachment.sourceDocument?.type === SourceDocumentType.VENDOR_INVOICE &&
      sourceDocumentAttachment.sourceDocument?.externalId
    ) {
      this.onDetachTempVendorInvoice(sourceDocumentAttachment.sourceDocument.externalId);
    } else {
      this.deleteTmpSourceDocumentAttachment(sourceDocumentAttachment);
    }
  }

  async openVendorInvoiceModal(operation: Operation): Promise<void> {
    // eslint-disable-next-line @nx/enforce-module-boundaries
    const { AttachVendorInvoiceModalComponent } = await import('@dougs/vendor-invoice/ui');
    this.vendorInvoices.set(
      (
        await lastValueFrom(
          this.modalService.open<VendorInvoice[]>(AttachVendorInvoiceModalComponent, { data: operation }).afterClosed$,
        )
      ).data || [],
    );
  }
}

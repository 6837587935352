import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, HostListener, Input } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { lastValueFrom } from 'rxjs';
import { take } from 'rxjs/operators';
import { LoggerService } from '@dougs/core/logger';
import { ModalService, OverlayCloseEvent } from '@dougs/ds';
import { Category } from '@dougs/operations/dto';
import { CategoryModalComponent } from './category-modal.component';

@Component({
  selector: 'dougs-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, FormsModule],
  providers: [{ provide: NG_VALUE_ACCESSOR, multi: true, useExisting: CategoryComponent }],
})
export class CategoryComponent implements ControlValueAccessor {
  @Input() categoryType:
    | 'investment'
    | 'expense'
    | 'expenseCredit'
    | 'cashPayment'
    | 'income'
    | 'incomeCredit'
    | 'operation' = 'investment';
  @Input() breakdownId?: number;
  @Input() operationId?: number;
  @Input() breakdownIsInbound?: boolean;
  @Input() wrap = false;
  @Input() placeholder = 'Non catégorisé';
  @Input() operationDate?: string;
  @Input() disableCategoryHelp = false;
  @Input() hasAlreadySendHelp = false;

  @HostBinding('class.to-filled')
  @Input()
  toFilled = false;

  @HostBinding('class.compact')
  @Input()
  isCompact = false;

  @HostBinding('class.select')
  isSelect = true;

  categorySelected?: Category;

  onChange!: (value: Category) => void;
  onTouch!: () => void;

  @HostBinding('class.disabled')
  isDisabled = false;

  constructor(
    private readonly modalService: ModalService,
    private readonly logger: LoggerService,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  @HostListener('click')
  async onClick(): Promise<void> {
    try {
      if (!this.isDisabled) {
        const closeEvent: OverlayCloseEvent<Category | null | undefined> = await lastValueFrom(
          this.modalService
            .open<
              Category,
              {
                type:
                  | 'investment'
                  | 'expense'
                  | 'expenseCredit'
                  | 'cashPayment'
                  | 'income'
                  | 'incomeCredit'
                  | 'operation';
                categorySelected: number | undefined;
                breakdownId: number | undefined;
                operationId: number | undefined;
                breakdownIsInbound: boolean | undefined;
                operationDate: string | undefined;
                disableCategoryHelp: boolean;
                hasAlreadySendHelp: boolean;
              }
            >(CategoryModalComponent, {
              data: {
                type: this.categoryType,
                categorySelected: this.categorySelected?.id,
                breakdownId: this.breakdownId,
                operationId: this.operationId,
                breakdownIsInbound: this.breakdownIsInbound,
                operationDate: this.operationDate,
                disableCategoryHelp: this.disableCategoryHelp,
                hasAlreadySendHelp: this.hasAlreadySendHelp,
              },
            })
            .afterClosed$.pipe(take(1)),
        );
        if (closeEvent?.data) {
          this.categorySelected = closeEvent.data;
          this.onChange(closeEvent.data);
          this.cdr.markForCheck();
        }
      }
    } catch (e) {
      this.logger.error(e);
    }
  }

  registerOnChange(fn: (value: Category) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  writeValue(obj: Category): void {
    this.categorySelected = obj;
    this.cdr.markForCheck();
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
    this.cdr.markForCheck();
  }
}

import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { endOfDay, format, isAfter, isBefore, isEqual, parseISO } from 'date-fns';
import { Company } from '@dougs/company/dto';
import { isValidBackDate } from '@dougs/core/utils';

export function createdOperationDateValidatorForCompany(
  company: Company,
  type: 'expense' | 'indemnity' | 'cashPayment' | 'vendorInvoice' | 'invoiceSupplier' | 'invoiceCustomer',
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value && isValidBackDate(control.value)) {
      const date = new Date(control.value);
      const firstOpeningAsDate = company.firstAccountingOpeningDate
        ? parseISO(company.firstAccountingOpeningDate)
        : undefined;

      if (firstOpeningAsDate && isBefore(date, firstOpeningAsDate)) {
        return {
          companyLockDate:
            `Vous ne pouvez pas ajouter une ${operationType(type)} dont la date est antérieure à la date` +
            ` de début d'exercice, soit le ${format(firstOpeningAsDate, 'dd/MM/yyyy')}.`,
        };
      }

      if (operationShouldNotBeInTheFuture(type, date)) {
        return {
          companyLockDate: `Vous ne pouvez pas ajouter une ${operationType(
            type,
          )} dont la date est ultérieure à aujourd'hui`,
        };
      }

      const lockDateAsDate = company.lockDate ? parseISO(company.lockDate) : undefined;
      if (lockDateAsDate && (isEqual(date, lockDateAsDate) || isBefore(date, lockDateAsDate))) {
        return {
          companyLockDate:
            `Vous ne pouvez pas ajouter une ${operationType(type)} à cette date car votre comptable Dougs` +
            ` a verrouillé vos opérations jusqu'au ${format(lockDateAsDate, 'dd/MM/yyyy')}.`,
        };
      }
    }

    return null;
  };
}

function operationShouldNotBeInTheFuture(code: string, date: Date) {
  return code !== 'vendorInvoice' && isAfter(date, endOfDay(new Date()));
}

const operationType = (type: string): string => {
  switch (type) {
    case 'expense':
      return 'note de frais';
    case 'indemnity':
      return 'indemnité kilométrique';
    case 'cashPayment':
      return 'recette en espèces';
    case 'vendorInvoice':
      return "facture d'achat";
    default:
      return '';
  }
};

<div class="title mb-8">
  <dougs-pill class="mr-4" type="admin" *ngIf="userStateService.loggedInUser.isAccountantOrAdmin && category.hidden">
    Cachée
  </dougs-pill>
  <dougs-pill class="mr-4" type="admin" *ngIf="showInboundPill(category)"> Remboursement</dougs-pill>
  <ng-container
    *ngIf="
      userStateService.loggedInUser.isAccountantOrAdmin &&
      category.resolvedAccountingNumbers &&
      category.resolvedAccountingNumbers.length > 0
    "
  >
    <dougs-pill
      class="mr-4"
      type="admin"
      *ngFor="let account of category.resolvedAccountingNumbers; trackBy: trackByAccount"
    >
      {{ account }}
    </dougs-pill>
  </ng-container>
</div>
<h5 class="mb-4" [ngClass]="[isPreferred ? category.group?.color || '' : '']" [innerHTML]="category.wording"></h5>
<p class="tiny" [innerHTML]="category.description"></p>

<form class="form-investment" (ngSubmit)="onSubmit()" [formGroup]="formGroup" *ngIf="formGroup">
  <div dougsModalTitle [ngClass]="{ 'from-candidate-invoice-title': fromCandidateInvoice }">
    <h6 *ngIf="!fromCandidateInvoice">
      {{ operation ? "Editer une facture d'achat" : "Ajouter une facture d'achat" }}
    </h6>
    <i dougsModalClose class="fal fa-times"></i>
  </div>
  <div
    dougsModalContent
    [ngClass]="{
      'from-candidate-invoice-modal': fromCandidateInvoice
    }"
  >
    <dougs-panel-info *ngIf="date?.hasError('companyLockDate')" [type]="'error'" class="mb-16">
      <span>{{ date?.getError('companyLockDate') }}</span>
    </dougs-panel-info>
    <div class="mb-16" *ngIf="fromCandidateInvoice">
      <h4>Créez une facture provisoire</h4>
      <p class="from-candidate-invoice">
        La facture provisoire vous permet de valider l'opération sans joindre la facture immédiatement. Plus tard,
        n'oubliez pas de déposer la facture réelle, l'administration fiscale peut l'exiger.
      </p>
    </div>
    <div class="form-field-line">
      <dougs-input-datepicker formControlName="date"></dougs-input-datepicker>
      <dougs-form-field>
        <label dougsFormFieldLabel for="amount">Montant</label>
        <input id="amount" type="number" formControlName="amount" min="0" step="0.01" dougsFormFieldControl />
        <i dougsFormFieldSuffix class="fas fa-euro-sign"></i>
        <span *ngIf="formService.isControlInvalid(amount)" dougsFormFieldError>
          <span *ngIf="amount.hasError('required')">Ce champ est requis</span>
        </span>
      </dougs-form-field>
      <dougs-form-field *ngIf="!fromCandidateInvoice">
        <label dougsFormFieldLabel for="invoiceNumber">Numéro de Facture</label>
        <input id="invoiceNumber" type="text" formControlName="invoiceNumber" dougsFormFieldControl />
        <span *ngIf="formService.isControlInvalid(invoiceNumber)" dougsFormFieldError>
          <span *ngIf="invoiceNumber.hasError('required')">Ce champ est requis</span>
        </span>
      </dougs-form-field>
    </div>
    <div class="mb-24" *ngIf="!fromCandidateInvoice">
      <dougs-checkbox
        formControlName="isCreditInvoice"
        appearance="toggle"
        [showYesNo]="true"
        label="C'est une facture d'avoir"
      ></dougs-checkbox>
    </div>

    <dougs-form-field *ngIf="!operation">
      <label dougsFormFieldLabel>Catégorie</label>
      <dougs-category
        formControlName="category"
        [categoryType]="isCreditInvoice.value ? 'expenseCredit' : 'expense'"
        dougsFormFieldControl
      ></dougs-category>
      <span *ngIf="formService.isControlInvalid(category)" dougsFormFieldError>
        <span *ngIf="category.hasError('required')">Ce champ est requis</span>
      </span>
    </dougs-form-field>
    <dougs-form-field *ngIf="!operation">
      <label dougsFormFieldLabel>Fournisseur</label>
      <dougs-select
        maxHeight="200"
        [searchable]="true"
        formControlName="supplier"
        placeholder="Sélectionner un fournisseur"
        dougsFormFieldControl
      >
        <dougs-select-option *ngFor="let supplier of suppliers; trackBy: 'id' | trackBy" [value]="supplier.id">
          {{ supplier.name }}
        </dougs-select-option>
      </dougs-select>
      <span *ngIf="formService.isControlInvalid(supplier)" dougsFormFieldError>
        <span *ngIf="supplier.hasError('required')">Ce champ est requis</span>
      </span>
    </dougs-form-field>
    <dougs-form-field *ngIf="!operation">
      <label dougsFormFieldLabel for="memo">Mémo</label>
      <input id="memo" type="text" formControlName="memo" dougsFormFieldControl />
    </dougs-form-field>
    <dougs-form-field *ngIf="!operation && !fromCandidateInvoice">
      <div class="files mt-8">
        <div class="file-list">
          <dougs-file-input
            (uploadFiles)="onUploadFiles($event)"
            [shouldAddVendorInvoice]="true"
            (attachFromVendorInvoice)="openVendorInvoiceModal(operation)"
          ></dougs-file-input>
          <dougs-source-document-pill
            *ngFor="let sourceDocumentAttachment of allSourceDocumentAttachments$(); trackBy: 'id' | trackBy"
            [sourceDocumentAttachment]="sourceDocumentAttachment"
            [canDelete]="true"
            (deleteSourceDocumentAttachment)="onDeleteSourceDocumentAttachment($event)"
          ></dougs-source-document-pill>
        </div>
      </div>
    </dougs-form-field>
    <dougs-panel-info class="responsibility-info small" *ngIf="fromCandidateInvoice">
      <i class="fal fa-circle-info color-primary"></i>
      <p class="small color-primary-700">
        Votre facture est définitivement perdue ? Catégorisez comme "achat sans facture". <br />Pour plus d'information,
        lisez notre article
        <a href="#" target="_blank" rel="noopener">l'importance de conserver vos factures </a>
        <i class="fal fa-external-link external-link-icon"></i> .
      </p>
    </dougs-panel-info>
  </div>
  <div
    dougsModalFooter
    [ngClass]="{
      'from-candidate-invoice-modal-footer': fromCandidateInvoice
    }"
  >
    <dougs-button color="secondary" dougsModalClose>Annuler</dougs-button>
    <dougs-button *ngIf="operation" color="delete" (click)="removeVendorInvoice()">
      Supprimer la facture d'achat
    </dougs-button>
    <dougs-button type="submit">
      {{ operation ? 'Modifier' : 'Créer' }}
    </dougs-button>
  </div>
</form>
<dougs-loader-fullpage *ngIf="isLoading$()">
  {{ operation ? 'Modification' : 'Création' }} en cours...
</dougs-loader-fullpage>

@if (hasAlreadySendHelp && (!categorySelected || categorySelected?.id === -1)) {
  <span class="italic color-gray" [class.wrap]="wrap"> Demande de catégorisation en cours </span>
} @else {
  <span
    [class.placeholder]="!categorySelected"
    [class.select-value]="categorySelected"
    [class.wrap]="wrap"
    [innerHTML]="categorySelected ? categorySelected.wording : placeholder"
  ></span>
}
<i class="fal fa-angle-down fa-lg"></i>

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'highlightSearch',
  standalone: true,
})
export class HighlightSearchPipe implements PipeTransform {
  transform(textToSearchIn: string, searchText: string): string {
    if (!searchText || !textToSearchIn) {
      return textToSearchIn;
    }

    const regex = new RegExp(searchText, 'gi');
    const match = textToSearchIn.match(regex);

    if (!match) {
      return textToSearchIn;
    }
    return textToSearchIn.replace(regex, `<span class="highlight">${match[0]}</span>`);
  }
}
